/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-content {
  margin-top: 5%;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #f8f8f8e1 !important;
  color: #2B2B2B !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  height: 150px;
}


nav.navbar.scrolled {
  padding: 0px 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  background-color: #f8f8f8e1;
}

nav.navbar button {
  color: #000;
  font-weight: 100;
  font-size: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  background-color: transparent;
  padding: 4px 14px;
  transition: 0.3s ease-in-out;
  margin-top: -7.5%;
  border-radius: 5px;
}
nav.navbar button:hover {
  color: #fff;
  background-color: #000;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #2B2B2B !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

.navbar-nav .nav-item .nav-link.dropdown-toggle {
  color: #2B2B2B !important;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 18px;
  opacity: 0.75;
  padding: 0 25px;
}

.navbar-nav .nav-item .nav-link.dropdown-toggle:focus, 
.navbar-nav .nav-item .nav-link.dropdown-toggle:hover {
  color: #2B2B2B !important;
  opacity: 1;
  background-color: transparent;
}

.navbar-nav .nav-item .nav-link.dropdown-toggle::after {
  display: none;
}


.dropdown-menu {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 9999;
}

.dropdown-menu .dropdown-item {
  color: #2B2B2B !important;
  opacity: 0.75;
  letter-spacing: 0.8px;
  font-size: 18px;
  z-index: 9999;

}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #2B2B2B !important;
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 52px;
  height: 42px;
  background: #fff;
  display: inline-flex;
  border-radius: 40%;
  border-color: #2B2B2B;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #2B2B2B;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #2B2B2B;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: #2B2B2B;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #2B2B2B;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #fff;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}



nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #2B2B2B;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #2B2B2B;
}
.navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
width: 24px;
position: absolute;
height: 2px;
background-color: #2B2B2B;
top: 0;
left: 0;
content: '';
z-index: 2;
transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
transform: rotate(45deg);
background-color: #2B2B2B;
height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
transform: translateY(8px) rotate(-45deg);
background-color: #2B2B2B;
height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
border-color: transparent;
}


/************ Banner Css ************/
.banner {
  margin-top: -100px;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #4A2FBD +1%,#fff 50%);
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 48px;
  margin-bottom: 24px;
  display: inline-block;
  color: #000;
}

.banner h4 {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.4;
  margin-bottom: 24px;
  display: block;
  color: #000;
}

.banner .section-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #000;
}

.banner .section-description {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 16px;
}

.banner .section-features {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  color: #666;
  line-height: 1.8;
  margin-bottom: 24px;
}

.banner button {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin-top: 40px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  background-color: transparent;
  padding: 18px 34px;
  transition: 0.3s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.banner button:hover {
  color: #fff;
  background-color: #000;
}


/************ Campaigns Css ************/
.campaign {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.campaign h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding: 40px 0;
}

.campaign h3 {
  font-size: 45px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}

.campaign p {
  color: #423f3f;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.campaign button {
  margin: 0 auto;
  padding: 1.7%;
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
  display: block;
}

.campaign .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.campaign .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.campaign .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.campaign .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.campaign .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#campaigns-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#campaigns-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#campaigns-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.campaign .btn {
  width: 100%;
  border-radius: 50px;
  background-color: #4A2FBD;
  border: none;
  font-weight: 700;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.campaign .btn:hover {
  background-color: #AA367C;
}
.campaign .card {
  margin-bottom: 20px;
}

.campaign .card:hover {
  transform: scale(1.05);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.campaign .card-title {
  text-align: center;
  color: black;
}

.campaign .card-footer {
  background-color: #4A2FBD;
  text-align: center;
}

.campaign .card-footer .nav-link {
  margin: 0 auto;
}
/************ daret Css ************/
.daret {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
  height: 100vh;
}
.daret h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding: 40px 0;
}
.daret p {
  color: #423f3f;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.daret button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: block;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 32px;
  background-color: #4A2FBD;
  border: none;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  z-index: 1000;
  position: relative;
}
.daret button:hover {
  background-color: #AA367C;
}
.daret .form-group {
  width: 40%;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 50px;
}
.daret .form-control {
  border-radius: 5px;
}
.daret .form-control:focus {
  box-shadow: none;
}
.daret .btn {
  width: 100%;
  border-radius: 50px;
  background-color: #4A2FBD;
  border: none;
  font-weight: 700;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.daret .btn:hover {
  background-color: #AA367C;
}

.daret .card {
  margin-bottom: 20px;
}

.daret .card:hover {
  transform: scale(1.05);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.daret .card-title {
  text-align: center;
  color: black;
}

.daret .card-footer {
  background-color: #4A2FBD;
  text-align: center;
}

.daret .card-footer .nav-link {
  margin: 0 auto;
}

/************ Project Card Css ************/


.proj-imgbx {
  position: relative;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 27px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-imgbx-desc {
  position: relative;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 27px;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

/************ login Css ************/
.login {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.login h2 {
  font-size: 65px;
  font-weight: 700;
  text-align: center;
  padding: 100px 0;
}

.login p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.login button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  margin-left: 50%;
  background-color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
}

.login button:hover {
  background-color: #333;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}
/************ portfolio Css ************/
.portfolio {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.portfolio h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding: 50px 0;
  color: #333;
}

.portfolio h3 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  padding: 50px 0;
  color: #333;
}

.portfolio p {
  color: #555;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 80%;
}

.portfolio img {
  position: relative;
  margin-top: 100px;
  margin-bottom: 27px;
  margin-left: 200px;
  object-fit: cover;
  overflow: hidden;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.portfolio table {
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  border-spacing: 0;
  width: 80%;
}

.portfolio th,
.portfolio td {
  color: #333;
  padding: 10px;
  font-size: 20px;
  letter-spacing: 0.8px;
  text-align: center;
  width: 50%;
  border: 1px solid #ccc;
}

.portfolio th {
  font-weight: bold;
  background-color: #f9f9f9;
}

.portfolio tbody td {
  background-color: #fff;
}

/************ Footer Css ************/
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0 0px 0;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 16px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* InfoTable.css */
.info-table-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid black; /* Add black border */
  border-radius: 8px; /* Add rounded corners */
}

.info-table {
  width: 100%;
}

.info-table th,
.info-table td {
  text-align: left;
  padding: 8px;
}

.info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.info-table tr:hover {
  background-color: #ddd;
}


/************ help Css ************/
.help {
  padding: 80px 0;
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.help h2 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  padding: 50px 0;
  color: #333;
}

.help h3 {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  padding: 50px 0;
  color: #333;
}

.help p {
  color: #555;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: left;
  width: 80%;
}

.help img {
  position: relative;
  object-fit: cover;
  overflow: hidden;
  width: 40%;
  height: 40%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.help ol{
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  border-collapse: separate;
  border-spacing: 0;
  width: 80%;
}


